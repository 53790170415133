import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Alert, AlertTitle, Container, Tabs, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CustomCard from "./Modules/CustomCard";
import ExtraInfoModal from "./Modules/ExtraInfoModal";
import FeaturedCard from "./Modules/FeaturedCard";
import theme from "./Modules/theme";
import debounce from "./Utils/Debounce";

const secondaryTextColor = "rgba(255, 255, 255, 0.7)";


function ScrollableCards(props: { children: React.ReactNode }) {

    return (
        <Box>
            <Tabs
                variant="scrollable"
                scrollButtons={true}
            >
                {props.children}
            </Tabs>
        </Box>
    );
}
function TopDiv() {
    // 288 is the height of the pfp image, so we use it as default height to use; most people will view this on a large screened device where the 288 value is what is used later too
    const [pfpHeight, setPfpHeight] = React.useState<number | undefined>(288);
    const pfpRef = React.useRef<HTMLImageElement>(null);

    // deals with window resizing: https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
    const [, setDimensions] = React.useState(window.innerWidth)
    React.useEffect(() => {
        const debouncedHandleResize = debounce(() => {
            setDimensions(window.innerWidth)
            setPfpHeight(pfpRef.current?.clientHeight);
        }, 50)

        window.addEventListener("resize", debouncedHandleResize)

        return () => {
            window.removeEventListener("resize", debouncedHandleResize)

        }
    })
    const links = { linkedin: "https://www.linkedin.com/in/iksteiger", github: "https://github.com/isteiger", youtube: "https://www.youtube.com/channel/UCX8m1Mtl75RQp3qlRxK1FXg" }

    return (
        <div style={{ paddingBottom: "1rem" }}>
            <div className="is-no-top-shadow is-bg-dark topbg" style={{ paddingTop: "20rem" }} >
                {/* appears on smaller screens */}
                <Box sx={{ boxShadow: 3 }} className="frost">
                    <Box style={{ width: "100%", flexDirection: "column", textAlign: "center" }} className="transparent appearOnSmallScreens">
                        <Container maxWidth="xl" style={{ alignItems: "end", height: "100%", padding: "0" }}>
                            <IconButton className="smallMarginRight" onClick={() => {
                                window.open(links.github, "_blank");
                            }}>
                                <GitHubIcon htmlColor={secondaryTextColor} fontSize="large" />
                            </IconButton>
                            <IconButton className="smallMarginRight" onClick={() => {
                                window.open(links.linkedin, "_blank");
                            }}>
                                <LinkedInIcon htmlColor={secondaryTextColor} fontSize="large" />
                            </IconButton>
                            <IconButton className="smallMarginRight" onClick={() => {
                                window.open(links.youtube, "_blank");
                            }}>
                                <YouTubeIcon htmlColor={secondaryTextColor} fontSize="large" />
                            </IconButton>
                        </Container>
                        <Container maxWidth="xl" style={{ alignItems: "end", padding: "0" }}>
                            <Typography variant="h6" component="div" style={{ display: "block", flexWrap: "wrap", color: secondaryTextColor }} className="centerUntilThing sectionHeadingText">
                                Student @ Cary High
                            </Typography>
                        </Container>
                        <Container maxWidth="xl" style={{ alignItems: "end", padding: "0" }}>
                            <Typography variant="h3" component="div" color="white" style={{ display: "block", flexWrap: "wrap" }} className="centerUntilThing sectionHeadingText">
                                Ian Steiger
                            </Typography>
                        </Container>
                    </Box>
                    <Box style={{ height: `calc(${pfpHeight}px - min(20vw, 100px))`, width: "100%", paddingBottom: ".5rem", display: "flex", flexDirection: "column" }} className="transparent">

                        <Container maxWidth="xl" style={{ alignItems: "end", height: "100%", padding: "0" }} className="hideForNow">
                            <div style={{ width: "calc(24px + 0.5rem + 288px + 8px)", maxWidth: "50vw", display: "inline-block" }}></div>
                            <IconButton className="smallMarginRight" onClick={() => {
                                window.open(links.github, "_blank");
                            }}>
                                <GitHubIcon htmlColor={secondaryTextColor} fontSize="large" />
                            </IconButton>
                            <IconButton className="smallMarginRight" onClick={() => {
                                window.open(links.linkedin, "_blank");
                            }}>
                                <LinkedInIcon htmlColor={secondaryTextColor} fontSize="large" />
                            </IconButton>
                            <IconButton className="smallMarginRight" onClick={() => {
                                window.open(links.youtube, "_blank");
                            }}>
                                <YouTubeIcon htmlColor={secondaryTextColor} fontSize="large" />
                            </IconButton>
                        </Container>
                        <Container maxWidth="xl" style={{ alignItems: "end", padding: "0" }} className="hideForNow">
                            <div style={{ width: "calc(24px + 0.5rem + 288px + 8px)", maxWidth: "70vw", display: "inline-block" }}></div>
                            <Typography variant="h6" component="div" style={{ display: "block", flexWrap: "wrap", color: secondaryTextColor }} className="centerUntilThing sectionHeadingText">
                                Student @ Cary High
                            </Typography>
                        </Container>
                        <Container maxWidth="xl" style={{ alignItems: "end", padding: "0" }} className="hideForNow">
                            <div style={{ width: "calc(24px + 0.5rem + 288px + 8px)", maxWidth: "70vw", display: "inline-block" }}></div>
                            <Typography variant="h3" component="div" color="white" style={{ display: "block", flexWrap: "wrap" }} className="centerUntilThing sectionHeadingText">
                                Ian Steiger
                            </Typography>
                        </Container>
                    </Box>
                </Box>
            </div>

            <Container maxWidth="xl" style={{ position: "relative" }}>
                <Box sx={{ boxShadow: 5 }} className="is-inline-flex is-circle is-pfp">
                    <picture style={{ display: "contents" }}>
                        <source srcSet="/assets/pfp.webp" type="image/webp" />
                        <source srcSet="/assets/pfp.jpg" type="image/jpeg" />
                        <img src="/assets/pfp.jpg" alt="Me" className="is-circle" ref={pfpRef} onLoad={() => { setPfpHeight(pfpRef.current?.clientHeight); }} />
                    </picture>
                </Box>
            </Container>
        </div>
    )
}
function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <Box sx={{ flexGrow: 1 }} className="is-bg-dark">
                    {/*<ResponsiveAppBar />*/}

                </Box>
                <TopDiv />
                <Container maxWidth="xl" >
                    <div className="siteBody">
                        <Alert severity="info">
                            <AlertTitle>Please excuse the mess</AlertTitle>
                            This site is a work in progress.
                        </Alert>
                        <br />
                        <FeaturedCard />
                        <Typography variant="h4" gutterBottom component="div" color="white" style={{ display: "flex", flexWrap: "wrap" }} className="centerUntilThing">
                            My Certifications
                        </Typography>
                        <ScrollableCards>
                            <CustomCard
                                title="MS Office Specialist: Excel Expert"
                                image="/assets/badges/msExcelExpert"
                                alt="MS Office Specialist: Excel Expert (Office 2019)"
                                imgFallback="png"
                                url="https://www.credly.com/badges/ae09ba32-e365-40a2-9889-e3ab954e3427/public_url"
                                body="Demonstrates advanced knowledge of Microsoft Excel."
                            />
                            <CustomCard
                                title="MS Office Specialist: Associate (Office 2019)"
                                image="/assets/badges/msSpecialist"
                                alt="MS Office Specialist: Associate (Office 2019)"
                                imgFallback="png"
                                url="https://www.credly.com/badges/35354489-41ef-4a91-aa88-47129442b20d/public_url"
                                body="Demonstrates knowledge of Microsoft Word, Excel, and PowerPoint."
                            />
                            <CustomCard
                                title="CompTIA IT Fundamentals+"
                                image="/assets/badges/comptiaITF"
                                alt="CompTIA IT Fundamentals Badge"
                                imgFallback="png"
                                url="https://www.credly.com/badges/dfb3f133-53c3-4c79-ae68-8228cafa1634"
                                body="An introduction to basic IT knowledge and skills."
                            />
                            <CustomCard
                                title="Intro to Programming Using Python"
                                image="/assets/badges/mtaPython"
                                alt="Introduction to Programming Using Python Badge"
                                imgFallback="png"
                                url="https://www.credly.com/badges/39dffc74-8554-48c7-9675-9bced88ad136/public_url"
                                body="A basic introduction to coding with Python."
                            />
                            <CustomCard
                                title="PCAP - Certified Associate Python Programmer"
                                image="/assets/badges/pcapBadge"
                                alt="PCAP - Certified Associate Python Programmer Badge"
                                imgFallback="png"
                                url="https://www.credly.com/badges/7d9d1b96-b3db-4506-b0f8-a071b7b2685f/public_url"
                                body="More advanced Python programming skills, including OOP."
                            />
                            <CustomCard
                                title="IT Specialist - Python"
                                image="/assets/badges/itsBadge"
                                alt="IT Specialist - Python Badge"
                                imgFallback="png"
                                url="https://www.credly.com/badges/ead63567-73ee-4c43-bd5e-33e6f8f76ea4/public_url"
                                body="Demonstates common Python programming skills needed for basic IT work."
                            />
                        </ScrollableCards>
                    </div>
                    <Typography variant="h4" gutterBottom component="div" color="white" style={{ display: "flex", flexWrap: "wrap" }} className="centerUntilThing">
                        Projects I've Worked On
                    </Typography>
                    <ScrollableCards>
                        <CustomCard title="PinkertonMC" image="/assets/pmcLogo" alt="PinkertonMC Logo" imgFallback="png" disableShadow={true} url="https://docs.microsoft.com/en-us/learn/certifications/mta-introduction-to-programming-using-python/" body="A Minecraft server network that aimed to set itself apart." />
                        <CustomCard title="Wake Students" image="/assets/wakeStudents" imgFallback="png" alt="Wake Students Logo" url="https://www.comptia.org/certifications/it-fundamentals" body="Website for unblocked games at school." />
                    </ScrollableCards>
                    <ExtraInfoModal image="/assets/wakeStudents" imgFallback="png" alt="Test Dialog" body="" url="" />
                </Container>
            </div >
        </ThemeProvider >
    );
}

export default App;
