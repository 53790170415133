import { Link } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export default function CustomCard(props: { title: string, image: string, url: string, body: string, disableShadow?: boolean, imgFallback: string, alt: string }) {
    return (
        <>
            <Card sx={{ minWidth: "18rem", maxWidth: "18rem", whiteSpace: "normal" }} className="is-text-white is-bg-light is-custom-card" style={{ boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.35)", display: "inline-flex", flexDirection: "column", marginBottom: "1rem", marginRight: ".5rem", marginLeft: ".5rem" }}>
                <CardMedia
                    className="is-bg-light"
                    component="div"
                    style={{ height: "140px", paddingBottom: "10px", paddingTop: "10px", textAlign: "center" }}
                >
                    <picture style={{ display: "contents" }}>
                        <source srcSet={`${props.image}.webp`} type="image/webp" />
                        <source srcSet={`${props.image}${props.imgFallback}`} type={`image/${props.imgFallback}`} />
                        <img src={`${props.image}.${props.imgFallback}`} style={{ maxHeight: "140px", filter: props.disableShadow ? "" : "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.35))" }} alt={props.alt} />
                    </picture>
                </CardMedia>
                <CardContent className="is-bg-dark" style={{ borderTopLeftRadius: "30px", borderTopRightRadius: "30px", boxShadow: "0px -3px 10px 0px rgba(0, 0, 0, 0.35)", flex: "1 1 auto" }}>
                    <Typography gutterBottom variant="h5" component="div" color="#fff">
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="#fff">
                        {props.body}
                    </Typography>
                </CardContent>
                <CardActions className="is-bg-dark">
                    <Link
                        component={Button}
                        size="small"
                        className="is-text-light"
                        href={props.url}
                        target="_blank"
                    >
                        Learn More
                    </Link>
                </CardActions>
            </Card>

        </>
    );
}

