import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#533B41",
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: "#745258",
        },
    },
    shape: {
        borderRadius: 10,
    },
});

export default theme;