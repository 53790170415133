import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Collapse, Typography } from "@mui/material";
import React from "react";

import SmartImageLoader from "./SmartImageLoader";

export default function FeaturedCard() {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
            <CardActionArea style={{ flexGrow: "1", height: "20rem" }} onClick={() => { window.open("https://chscompclub.com", "_blank"); }}>
                <CardContent style={{ height: "100%", justifyContent: "center", display: "flex", alignItems: "center", flexGrow: "1", zIndex: "1", background: "#000 no-repeat" }}>
                    <SmartImageLoader imageURL="/assets/chscompclubBanner" types={["webp", "png"]} fallback="png" alt="Neofetched Logo" style={{ maxWidth: "70vw", maxHeight: "20rem" }} />
                </CardContent>
            </CardActionArea>
            <CardActions className="is-bg-dark" style={{ width: "100%", display: "flex", padding: "0px", color: "white", zIndex: "2" }}>
                <CardHeader
                    action={
                        <Button variant="text" endIcon={<ExpandMoreIcon style={{ transform: !expanded ? "rotate(0deg)" : "rotate(180deg)" }} className="expandButtonTransition" />} style={{ color: "white" }} onClick={handleExpandClick}>
                            Learn More
                        </Button>
                    }
                    sx={{ color: "white", width: "100%" }}
                    title="Cary High School Computer Club"
                    subheader="Click above to visit"
                />
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className="is-bg-dark is-text-white">
                    <Typography variant="h5" gutterBottom>About Us</Typography>
                    <Typography paragraph>
                        Along with my IT teacher, Mr. Stone, and some of my classmates, I created the Cary High Computer Club to facilitate our interests in IT and computers. We started in the 2023-24 school year, and work earnestly on it. Currently I am creating a website, and we have created Instagram and YouTube accounts. We will be working with things such as enterprise server equipment and older consumer hardware to work on random projects and learn a ton in the process. Some projects we are thinking about doing are creating an Active Directory domain and putting the classroom computers on it, and creating a large cluster of old ThinkCentre desktops we have
                    </Typography>
                    <Typography variant="h5" gutterBottom>What's in the pipeline?</Typography>
                    <Typography paragraph>
                        Since we don't have much time due to school, we can't upload very often. But we have a ton of ideas for videos. Like, an entire Google Doc full.
                        Some include Linux on the Wii U, or using an old ThinkPad in 2022.
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    )
}