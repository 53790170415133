interface RestProps { }

export default function SmartImageLoader({ imageURL, types, alt, fallback, ...restProps }: { imageURL: string, types: string[], alt: string, fallback: string, style: Object } & RestProps) {
    return (
        <>
            <picture {...restProps}>
                {types.map((type) => {
                    return (
                        <source srcSet={`${imageURL}.${type}`} type={`image/${type}`} {...restProps} />
                    )
                })}
                <img src={`${imageURL}.${fallback}`} alt={alt} {...restProps} />
            </picture>
        </>
    );
}

