import CloseIcon from "@mui/icons-material/Close";
import { CardMedia } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
    style: object;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[100],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function ExtraInfoModal(props: { styles?: { topColor: string, cardBackground: string, textColor: string, closeButtonColor: string, closeIconColor: string }, image: string, imgFallback: string, body: string, url: string, alt: string, disableShadow?: boolean }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    let obj = {};
    let closeButtonColor: string;
    let topColor: string;

    if (props.styles) {
        closeButtonColor = props.styles.closeButtonColor;
        topColor = props.styles.topColor;
    }
    else {
        closeButtonColor = "#A58E91";
        topColor = "#A58E91";
    }
    if (props.styles?.cardBackground === undefined || props.styles?.textColor === undefined) {
        obj = { backgroundColor: "#533B41", color: "#fff" };
    } else {
        obj = { backgroundColor: props.styles.cardBackground, color: props.styles.textColor };
    }

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Open dialog
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <div style={{ backgroundColor: topColor }}>
                    <CardMedia
                        component="div"
                        style={{ height: "140px", paddingBottom: "calc(1.5rem + 16px)", paddingTop: "calc(1.5rem + 16px)", textAlign: "center", backgroundColor: topColor }}
                    >
                        <picture style={{ display: "contents" }}>
                            <source srcSet={`${props.image}.webp`} type="image/webp" />
                            <source srcSet={`${props.image}${props.imgFallback}`} type={`image/${props.imgFallback}`} />
                            <img src={`${props.image}.${props.imgFallback}`} style={{ maxHeight: "140px", filter: props.disableShadow ? "" : "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.35))" }} alt={props.alt} />
                        </picture>
                    </CardMedia>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} style={Object.assign({ text: "white", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", boxShadow: "0px -3px 10px 0px rgba(0, 0, 0, 0.35)", flex: "1 1 auto" }, obj)}>
                        Modal title
                    </BootstrapDialogTitle>
                </div>
                <DialogContent dividers style={obj}>
                    <Typography gutterBottom>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.
                    </Typography>
                    <Typography gutterBottom>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                        Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                    </Typography>
                    <Typography gutterBottom>
                        Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
                        magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
                        ullamcorper nulla non metus auctor fringilla.
                    </Typography>
                </DialogContent>
                <DialogActions className="is-bg-dark">
                    <Button autoFocus onClick={handleClose} style={{ color: closeButtonColor }}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
